@font-face {
  font-family: 'Avenir';
  src: url("/assets/fonts/avenir/Avenir-Light.woff2") format('woff2'), url("/assets/fonts/avenir/Avenir-Light.woff") format('woff');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Avenir';
  src: url("/assets/fonts/avenir/Avenir-LightOblique.woff2") format('woff2'), url("/assets/fonts/avenir/Avenir-LightOblique.woff") format('woff');
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: 'Avenir';
  src: url("/assets/fonts/avenir/Avenir-Roman.woff2") format('woff2'), url("/assets/fonts/avenir/Avenir-Roman.woff") format('woff');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Avenir';
  src: url("/assets/fonts/avenir/Avenir-Oblique.woff2") format('woff2'), url("/assets/fonts/avenir/Avenir-Oblique.woff") format('woff');
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: 'Avenir';
  src: url("/assets/fonts/avenir/Avenir-Medium.woff2") format('woff2'), url("/assets/fonts/avenir/Avenir-Medium.woff") format('woff');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Avenir';
  src: url("/assets/fonts/avenir/Avenir-Heavy.woff2") format('woff2'), url("/assets/fonts/avenir/Avenir-Heavy.woff") format('woff');
  font-weight: 800;
  font-style: normal;
}
.account-ledger {
  width: 100vw;
  height: 100vh;
  min-width: 900px;
  overflow: scroll;
}
.account-ledger__header {
  top: 0;
  z-index: 2;
  position: fixed;
  width: 100%;
  background: #fafafa;
  height: 50px;
  border-bottom: 1px solid rgba(29,29,29,0.13);
  min-width: 900px;
}
.account-ledger__header__buffer {
  width: 100%;
  position: fixed;
  z-index: 1;
  height: 50px;
  top: 50px;
  background: #fafafa;
  display: flex;
  align-items: center;
  margin-left: 20px;
}
.account-ledger__title {
  position: absolute;
  height: 100%;
  font-weight: 400;
  font-size: 25px;
  color: #1f355e;
  display: flex;
  align-items: center;
  margin-left: 10px;
}
.account-ledger__integration-logo {
  padding-right: 15px;
  height: 20px;
  width: auto;
}
.account-ledger__integration-logo__wrapper {
  display: inline-flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 50px;
}
.account-ledger__wrapper {
  position: relative;
  top: 140px;
  width: 100%;
  display: flex;
  justify-content: center;
  min-height: 650px;
  height: calc(100% - 90px);
}
.account-ledger__category-header {
  font-weight: 600;
  background: #f5f5f5;
  top: 100px;
  position: fixed;
  width: 80%;
  left: 50%;
  margin-left: -40%;
  height: 40px;
  border: 2px solid #eceff1;
  font-size: 14px;
  display: grid;
  grid-template-columns: 16% 18% 16% 20% 17% 14%;
  grid-template-rows: 40px;
  grid-gap: 10px;
  color: #1f355e;
  padding: 0 10px;
  min-width: 600px;
  z-index: 2;
}
.account-ledger__category {
  display: flex;
  align-items: center;
  width: 100%;
}
.account-ledger__category__amount,
.account-ledger__category__text,
.account-ledger__category__date {
  display: flex;
  align-items: center;
}
.account-ledger__category__text,
.account-ledger__category__date {
  border-right: 2px solid #eceff1;
}
.account-ledger__category__number,
.account-ledger__category__text {
  justify-content: flex-start;
}
.account-ledger__category__date {
  justify-content: center;
}
@media (max-width: 1000px) {
  .account-ledger__category-header {
    position: absolute;
    width: 100%;
    margin-left: 0;
    left: 0;
    min-width: 900px;
  }
}
.checkout {
  width: 300px;
  height: 500px;
  padding-top: 25px;
  border-radius: 3px;
  border: 1px solid #eceff1;
  display: flex;
  position: relative;
  justify-content: center;
  background: #fff;
  transition: border 0.2s ease;
}
.checkout__error-message {
  width: 100%;
  display: block;
  font-size: 10px;
  opacity: 0;
  color: #f44336;
  transition: opacity 0.2s ease;
}
.checkout__error-message.error {
  opacity: 1;
}
.checkout__privacy-policy {
  display: block;
  padding-top: 9px;
  font-size: 10px;
  cursor: pointer;
}
.checkout__privacy-policy:hover {
  color: #e15829;
}
.checkout__input,
.checkout__input__cvv,
.checkout__input__zipcode {
  padding: 8px 5%;
  border-radius: 3px;
  border: 1px solid #eceff1;
  color: #424242;
  font-family: 'Avenir', 'Helvetica Neue', Arial, Helvetica, sans-serif;
  font-size: 14px;
  margin-bottom: 8px;
  transition: border 0.2s ease;
}
.checkout__input {
  width: 90%;
}
.checkout__input__zipcode {
  width: 68px;
  margin-left: 10px;
}
.checkout__input__cvv {
  width: 72px;
}
.checkout__input__wrapper {
  width: 240px;
}
.checkout__input__small {
  width: 100%;
  display: inline-block;
}
.checkout__input__small__exp,
.checkout__input__small__cvv {
  display: inline-flex;
  justify-content: flex-start;
}
.checkout__input__small__exp {
  width: 60%;
}
.checkout__input__small__cvv {
  width: 40%;
}
.checkout__label__zipcode {
  margin-left: 10px;
}
.checkout label {
  font-size: 11px;
  color: #757575;
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 8px;
}
.checkout input:focus,
.checkout select:focus {
  outline: none;
}
.checkout input:focus {
  border: 1px solid #607d8b;
}
.checkout .monthExp,
.checkout .yearExp {
  height: 37px;
  width: 60px;
  font-size: 12px;
  color: #424242;
  background: #fff;
  border: 1px solid #eceff1;
  margin-top: 5px;
  margin-right: 12px;
}
.submit {
  display: block;
  margin-top: 28px;
  min-width: 100px;
}
.error {
  border-color: #f44336;
}
@media (max-width: 840px) and (min-width: 650px) {
  .checkout {
    border: none;
    width: auto;
    border-radius: 0;
  }
}
h3 {
  font-family: 'Avenir', 'Helvetica Neue', Arial, Helvetica, sans-serif;
  font-size: 24px;
}
h6 {
  font-family: 'Avenir', 'Helvetica Neue', Arial, Helvetica, sans-serif;
  font-size: 14px;
}
body {
  height: 100%;
  width: 100%;
  background: #fafafa;
}
.guidance-notification {
  text-align: center;
  position: fixed;
  bottom: 0;
  right: 0;
  width: 300px;
  background: #e15829;
  z-index: 3;
  height: 60px;
  margin: 10px;
  padding: 10px;
  border-radius: 5px;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  opacity: 1;
  transition: opacity 0.2s ease;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.hide-notification {
  opacity: 0;
}
.marketplace {
  width: 100vw;
  height: 100vh;
}
.marketplace__bestsellers {
  font-size: 25px;
  font-weight: 500;
  color: #e15829;
  margin-bottom: 3px;
  margin-left: 15px;
}
.marketplace__bestsellers__blue {
  color: #1f355e;
}
.marketplace__header {
  top: 0;
  z-index: 2;
  position: fixed;
  width: 100%;
  background: #fafafa;
  height: 50px;
  border-bottom: 1px solid rgba(29,29,29,0.13);
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.marketplace__header__buffer {
  width: 100%;
  position: fixed;
  z-index: 1;
  height: 47px;
  top: 50px;
  display: flex;
  align-items: center;
  margin-left: 20px;
}
.marketplace__logo {
  cursor: pointer;
  height: 100%;
}
.marketplace__content {
  position: relative;
  top: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 650px;
  height: calc(100% - 50px);
}
.marketplace__content__product-page__header {
  width: 100%;
  height: 40px;
}
.marketplace__content__product-page__back {
  font-family: 'Avenir', 'Helvetica Neue', Arial, Helvetica, sans-serif;
  font-size: 12px;
  font-weight: 800;
  color: #607d8b;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding-left: 0.5em;
  padding-right: 0.5em;
  background: none;
  cursor: pointer;
  transition: color 0.5s ease;
}
.marketplace__content__product-page__back:hover {
  color: #e15829;
}
.marketplace__content__grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 15px;
}
.marketplace__content__grid__wrapper {
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
@media (max-width: 820px) {
  .marketplace__content.product-page__open {
    min-height: calc(100% - 51px);
  }
  .marketplace__content {
    min-height: 850px;
  }
  .marketplace__content__grid {
    margin-top: 60px;
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 650px) {
  .marketplace__content__product-page__wrapper {
    height: 600px;
  }
}
@media (max-width: 550px) {
  .marketplace__content {
    margin-top: 20px;
    min-height: auto;
    height: auto;
  }
  .marketplace__content__grid {
    grid-template-columns: repeat(1, 1fr);
    padding-bottom: 20px;
    margin-top: 10px;
  }
  .marketplace__content__grid__wrapper {
    align-items: flex-start;
    margin: 20px 0;
  }
}
@media (max-width: 400px) {
  .marketplace__bestsellers {
    display: none;
  }
}
@media (min-height: 900px) {
  .marketplace__header {
    height: 70px;
  }
  .marketplace__header__buffer {
    top: 70px;
    font-size: 15px;
  }
  .marketplace__bestsellers {
    font-size: 35px;
  }
}
.product,
.product-plan {
  background: #fff;
  color: #424242;
  border-radius: 3px;
  padding: 20px;
  font-size: 150%;
  min-height: 250px;
  cursor: pointer;
  border: 1px solid rgba(29,29,29,0.13);
  font-family: 'Avenir', 'Helvetica Neue', Arial, Helvetica, sans-serif;
  transition: border 0.5s ease;
}
.product__image,
.product-plan__image {
  width: 100%;
}
.product__image__wrapper,
.product-plan__image__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.product p,
.product-plan p {
  font-size: 14px;
}
.product-plan {
  min-height: 400px;
  width: 300px;
}
.product-plan:hover {
  border: 1px solid #78909c;
}
.product-plan__header {
  align-items: center;
  color: #607d8b;
  display: flex;
  justify-content: center;
}
.product-plan__details {
  font-size: 14px;
  margin: 5px 0 0 0;
}
.product-plan__details__wrapper {
  color: #1f355e;
  margin-top: 1rem;
  text-align: center;
}
.product-plan__details__price {
  font-weight: 600;
  margin: 0;
}
.product-plan__descriptions {
  margin: 20px 0;
}
.product-plan__description {
  color: #607d8b;
  font-weight: 600;
  margin: 5px;
}
.product-plan__description__icon {
  fill: #607d8b;
}
.product-plan__description__wrapper {
  display: flex;
  align-items: center;
}
.product-plan__buy {
  align-items: center;
  background: #e15829;
  border-radius: 3px;
  color: #fff;
  display: flex;
  font-size: 18px;
  font-weight: 600;
  height: 40px;
  justify-content: center;
  margin-top: 1rem;
}
.product-plan__image__wrapper {
  width: 100px;
  height: 130px;
}
.product:hover {
  border: 1px solid #90a4ae;
}
.product__details__wrapper {
  position: relative;
}
.product__name,
.product__price {
  margin: 0;
  font-size: 14px;
  color: #37474f;
}
.product__name {
  font-weight: 800;
  width: 120px;
  margin-top: 10px;
}
.product__image__wrapper {
  width: 170px;
  height: 200px;
}
.product__buy {
  position: absolute;
  text-transform: uppercase;
  font-size: 14px;
  padding: 5px 10px;
  text-align: center;
  font-weight: 800;
  color: #fff;
  background: #e15829;
  border-radius: 3px;
  right: 0;
  top: 0;
}
@media (min-width: 550px) and (max-width: 820px) {
  .product {
    height: 260px;
  }
  .product__image__wrapper {
    width: 170px;
    height: 180px;
  }
}
.product-page {
  display: inline-flex;
  color: #1f355e;
  background: #fff;
  width: 800px;
  border: 1px solid rgba(29,29,29,0.13);
  border-radius: 7px 7px 0em 0em;
}
.product-page__size__label {
  font-size: 11px;
  font-weight: 600;
  color: #1f355e;
  text-transform: uppercase;
}
.product-page__size__select {
  color: #1f355e;
  background: #fff;
}
.product-page__guidance {
  font-weight: 600;
  color: #e15829;
  position: absolute;
  top: 15px;
}
.product-page__view-transaction {
  width: 200px;
}
.product-page__form {
  padding-top: 20px;
}
.product-page__price {
  margin-top: 10px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 500;
}
.product-page__name {
  margin: 0;
}
.product-page__image {
  height: 100%;
}
.product-page__image__wrapper {
  margin: 0 0 50px 50px;
  height: 500px;
  overflow: hidden;
  border: 1px solid #eceff1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.product-page__left,
.product-page__right {
  width: 50%;
  height: 100%;
  margin-top: 50px;
}
.product-page__right {
  margin-left: 50px;
}
.product-page__checkout {
  transition: opacity 0.2s ease;
}
.product-page__receipt {
  margin: 0;
  font-size: 12px;
}
.product-page__receipt__header {
  margin-bottom: 20px;
}
.product-page__receipt__order-number {
  font-weight: 500;
}
@media (max-width: 840px) {
  .product-page {
    padding-left: 30px;
    width: 100%;
  }
  .product-page__name,
  .product-page__price,
  .product-page__right {
    margin-left: 0;
  }
  .product-page__left {
    margin: 50px 0;
  }
  .product-page__image {
    width: 320px;
    height: auto;
  }
  .product-page__image__wrapper {
    width: 100%;
    margin: 0 30px 0 0;
    padding-top: 25px;
  }
}
@media (max-width: 650px) {
  .product-page {
    border: none;
    width: 90%;
    background: #fafafa;
  }
  .product-page__left {
    display: none;
  }
  .product-page__left,
  .product-page__right {
    margin-top: 0;
  }
  .product-page__name {
    font-size: 18px;
  }
  .product-page__price {
    font-size: 12px;
  }
  .product-page__name,
  .product-page__price {
    margin: 0;
  }
}
@media (max-width: 550px) {
  .product-page {
    border: none;
    width: 90%;
  }
  .product-page__left {
    display: none;
  }
  .product-page__name {
    font-size: 18px;
  }
  .product-page__price {
    font-size: 12px;
  }
}
.transactions__wrapper {
  width: 80%;
  text-align: center;
}
.transaction {
  height: 40px;
  font-size: 14px;
  display: grid;
  grid-template-columns: 16% 18% 16% 20% 17% 9%;
  grid-template-rows: 40px;
  grid-gap: 10px;
  background: #fff;
  border-bottom: 2px solid #eceff1;
  border-left: 2px solid #eceff1;
  border-right: 2px solid #eceff1;
  color: #1f355e;
  padding: 0 10px;
  min-width: 600px;
  font-weight: 500;
}
.transaction__amount,
.transaction__text,
.transaction__date {
  display: flex;
  align-items: center;
}
.transaction__text,
.transaction__date {
  border-right: 2px solid #eceff1;
}
.transaction__amount {
  justify-content: flex-end;
  padding-right: 10px;
}
.transaction__text {
  justify-content: flex-start;
}
.transaction__date {
  justify-content: center;
}
.transaction span {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
@media (max-width: 1000px) {
  .transactions__wrapper {
    width: 100%;
  }
}
.email-editor__wrapper {
  display: flex;
  justify-content: center;
  margin-top: 60px;
}
.email-editor__form {
  width: 800px;
}
.email-editor__greeting {
  height: 80px;
  width: 100%;
  margin: 10px 0;
}
.email-editor__message {
  height: 200px;
  width: 100%;
  margin: 10px 0;
}
.email-editor__key-input {
  display: block;
  margin: 10px 0;
}
/* Border-Box */
*,
*:before,
*:after {
  box-sizing: inherit;
}
html {
  box-sizing: border-box;
}
.primary {
  background: #e15829;
  font-size: 14px;
  color: #fff;
  border-radius: 3px;
  padding: 10px 15px;
  text-shadow: none;
  margin-right: 1em;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  cursor: pointer;
  transition: background 0.2s ease;
  text-align: center;
}
.primary:hover {
  background: #4caf50;
}
a {
  color: inherit;
  text-decoration: none;
}
